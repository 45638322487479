// ******************************
// 【プロジェクト基本設定】
// ******************************


//カラー設定 --------------------
$Cbase: #333; //ベースカラー
$Ctext: #333; //文字カラー
$Clink: #1e17c7; //リンクカラー

//メディアクエリ --------------------
$full : 1600px;     //フルスクリーン用サイズ
$pc   : 1000px;     //パソコン用サイズ
$tb   : 1000px;     //タブレット用サイズ
$sp   : 768px;      //スマートフォン用サイズ
$maxW : max-width;  //メディアクエリ用サイズ指定（最大幅）
$minW : min-width;  //メディアクエリ用サイズ指定（最小幅）
$maxH : max-height; //メディアクエリ用サイズ指定（最大高さ）
$minH : min-height; //メディアクエリ用サイズ指定（最小高さ）

@mixin mqW( $widthType, $breakPointSize: $pc ) {
  @media screen and ( $widthType : $breakPointSize ) {
    @content;
  }
}
@mixin mqH( $heightType, $breakPointSize: $pc ) {
  @media screen and ( $heightType : $breakPointSize ) {
    @content;
  }
} //メディアクエリmixin

@mixin mqDarkMode() {
  @media (prefers-color-scheme: dark) {
    @content;
  }
} // ダークモード用メディアクエリmixin




