@charset "UTF-8";
/* ****************************** */
/* 【ベースCSS】 */
/* ****************************** */
* {
  box-sizing: border-box;
  &::selection {
    background-color: rgba(204, 204, 204, 0.5);
  }
}
body{
  font-family:
    "Noto Sans",
    "Noto Serif",
    "Noto Sans JP",
    "Noto Sans Japanese",
    "Avenir" ,
    "Open Sans" ,
    "Helvetica Neue" ,
    "Helvetica" ,
    "Arial" ,
    "Verdana" ,
    "Roboto" ,
    "游ゴシック" ,
    "Yu Gothic" ,
    "游ゴシック体" ,
    "YuGothic" ,
    "ヒラギノ角ゴ Pro W3" ,
    "Hiragino Kaku Gothic Pro" ,
    "Meiryo UI" ,
    "メイリオ" ,
    "Meiryo" ,
    "ＭＳ Ｐゴシック" ,
    "MS PGothic" ,
    sans-serif;
  font-weight: 100;
  font-size: 16px;
}

img {
  font-size: 0;
  letter-spacing: 0;
}

br {
  line-height: 1.7;
}

a,
a:focus {
  text-decoration: none;
}
sup {
  vertical-align: super;
  font-size: smaller;
}
sub {
  vertical-align: sub;
  font-size: smaller;
}
ins {
  background-color: #99ff99;
  &::before,
  &::after {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
  }
  &::before {
    content: " [挿入開始] ";
  }
  &::after {
    content: " [挿入終了] ";
  }
}
del {
  color: red;
  &::before,
  &::after {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
  }
  &::before {
    content: " [削除開始] ";
  }
  &::after {
    content: " [削除終了] ";
  }
}
address {
  font-style: normal;
}

label,
button,
input[type="button"] {
  cursor: pointer;
} // カーソルオン要素
input,
textarea,
select,
button {
  background-color: #fff;
  padding: 0;
  border: 1px solid #333;
  border-radius: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  &[type="search"] {
    appearance: none;
    &::-webkit-search-decoration {
      display: none;
    }
    // &::-webkit-search-cancel-button {
    //   appearance: none;
    // }
  }
  &[type="time"],
  &[type="week"],
  &[type="month"],
  &[type="date"],
  &[type="datetime-local"] {
    width: 200px;
  } // input要素（日付系）
  &:focus {
    border-radius: 0;
    outline-offset: -2px;
    outline-width: 2px;
    outline-style: solid !important;
  }
} // ユーザー対話要素
dialog {
  position: static;
  display: inline-block;
  padding: 0;
  border-width: 1px;
  margin: 0;
} // ダイアログ要素

table {
  background-color: #fff;
  & thead,
  & tbody,
  & tfoot {
    border: 1px solid #333;
  }
  & th,
  & td {
    border: 1px solid #333;
    text-align: left;
  }
} // テーブル要素

.clearfix::after {
  content: "";
  display: block;
  clear: both;
} // clearfix

