// ******************************
// 【ユーティリティclass】
// ******************************

@for $i from 1 through 4 {
  .u-mb#{$i * 10} {
    margin-bottom: #{$i * 10}px;
  }
} // .u-mb10 形式のセレクタを生成（10〜40まで）。

